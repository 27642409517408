import naja from 'naja';
import DependentSelectBox from '@wavevision/dependent-selectbox';
import ResponsiveCarousel from '@flowgate/core/ui/Components/ResponsiveCarousel/assets';
import { Options } from 'naja/dist/Naja';

import ProcessingPayment from '../../../app/FrontModule/Components/ProcessingPayment/assets';
import ProductDetail from '../../../app/FrontModule/ProductsModule/Components/ProductDetail/assets';
import ProductDetailModal from '../../../app/FrontModule/ProductsModule/Components/ProductDetailModal/assets';
import SpecificationForm from '../../../app/FrontModule/OnlinePrintModule/DigitalPrintModule/Forms/SpecificationForm/assets';
import Table from '../../../app/UiModule/Components/Table/assets';
import Upload from '../../../app/UiModule/Components/FormControls/Upload/assets';

import DisableOnInputValue from './DisableOnInputValue';
import Dropdown from './Dropdown';
import Forms from './Forms';
import SubmitForm from './SubmitForm';

export const defaultOptions: Options = {
  ajax: 'data-ajax',
  forceRedirect: true,
  selector: '[data-ajax]',
};

naja.uiHandler.selector = defaultOptions.selector;

const initialize = (): void => naja.initialize(defaultOptions);

naja.registerExtension(new DependentSelectBox());
naja.registerExtension(new DisableOnInputValue());
naja.registerExtension(Dropdown);
naja.registerExtension(Forms);
naja.registerExtension(new ProcessingPayment());
naja.registerExtension(new ProductDetail());
naja.registerExtension(new ProductDetailModal());
naja.registerExtension(new ResponsiveCarousel());
naja.registerExtension(new SpecificationForm());
naja.registerExtension(new SubmitForm());
naja.registerExtension(Table);
naja.registerExtension(Upload);

document.addEventListener('DOMContentLoaded', initialize);

export default naja;
