import getElementById from '@wavevision/ts-utils/dom/getElementById';
import getElementBySelector from '@wavevision/ts-utils/dom/getElementBySelector';
import { locale } from '@flowgate/core/assets/app';

import {
  ZASILKOVNA,
  ZASILKOVNA_BRANCH,
  ZASILKOVNA_SELECT_BRANCH,
} from './constants';
import {
  PacketaExtendedPoint,
  WindowWithPacketa,
  ZasilkovnaData,
} from './types';

declare const window: WindowWithPacketa;

const getInput = (name: string): HTMLInputElement =>
  getElementBySelector(`input[name='${name}'`) as HTMLInputElement;

const getText = (): HTMLDivElement =>
  getElementById(ZASILKOVNA_BRANCH) as HTMLDivElement;

const handleChoose = (extendedPoint: PacketaExtendedPoint | null): void => {
  if (extendedPoint) {
    const input = getInput(ZASILKOVNA);
    const text = getText();
    const { id, street, district, city } = extendedPoint;
    const name = extendedPoint.place;
    const zip = extendedPoint.zip.replace(' ', '');
    input.value = JSON.stringify({
      id,
      name,
      street,
      city,
      zip,
    });
    text.innerText = [name, street, district, city, zip]
      .filter(part => part)
      .join(', ');
  }
};

const handleClick = (e: MouseEvent): void => {
  e.preventDefault();
  const button = e.target as HTMLButtonElement;
  const label = button.parentElement?.parentElement;
  if (label) label.click();
  const json = button.getAttribute('data-zasilkovna') as string;
  const data = JSON.parse(json) as ZasilkovnaData;
  window.Packeta.Widget.pick(data.apiKey, handleChoose, {
    country: data.country,
    language: locale,
  });
};

const initButton = (button: HTMLButtonElement): void => {
  button.addEventListener('click', handleClick);
};

const init = (): void => {
  const button = getElementById<HTMLButtonElement>(ZASILKOVNA_SELECT_BRANCH);
  if (button) initButton(button);
};

export default { init };
