import { Naja } from 'naja';
import { Extension } from 'naja/dist/Naja';

import NetteForms from 'nette-forms';

import DependentHandler from './DependentHandler';
import {
  FORM_SELECTOR,
  FORM_SUBMIT_IGNORED,
  FORM_SUBMIT_FORCE,
  HAS_SUBMIT_FORM,
} from './constants';

class SubmitForm implements Extension {
  private dependentHandler: DependentHandler;

  private naja: Naja;

  private keyupTimeout: NodeJS.Timeout;

  public constructor() {
    this.dependentHandler = new DependentHandler();
  }

  public readonly initialize = (naja: Naja): void => {
    this.naja = naja;
    this.dependentHandler.init(naja, { onLoaded: this.handleSubmit() });
    this.naja.addEventListener('init', this.load);
    this.naja.addEventListener('complete', this.load);
  };

  public readonly load = (): void => {
    const forms = document.querySelectorAll(
      FORM_SELECTOR,
    ) as NodeListOf<HTMLFormElement>;
    for (const element of forms) {
      let form = element;
      let button = null;
      if (element.type === 'button' || element.type === 'submit') {
        button = element.name;
        ({ form } = element);
      }
      if (
        form instanceof HTMLFormElement &&
        form.getAttribute(HAS_SUBMIT_FORM) === null
      ) {
        this.dependentHandler.addForm(form);
        form.addEventListener('input', this.handleDebouncedChange(button));
        form.setAttribute(HAS_SUBMIT_FORM, '');
      }
    }
  };

  private handleChange = (button: string | null = null) => (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (!target.hasAttribute(FORM_SUBMIT_IGNORED)) {
      this.handleSubmit(button)({
        form: target.form as HTMLFormElement,
        forceSubmit: target.hasAttribute(FORM_SUBMIT_FORCE),
      });
    }
  };

  private handleDebouncedChange = (button: string | null = null) => (
    event: Event,
  ): void => {
    clearTimeout(this.keyupTimeout);
    this.keyupTimeout = setTimeout(() => this.handleChange(button)(event), 250);
  };

  private handleSubmit = (button: string | null = null) => ({
    form,
    forceSubmit,
  }: {
    form: HTMLFormElement;
    forceSubmit?: boolean;
  }): void => {
    if (this.dependentHandler.isReady(form)) {
      const valid = !forceSubmit && NetteForms.validateForm(form, true);
      if (forceSubmit || valid) {
        if (button && form[button]) {
          this.naja.uiHandler.clickElement(form[button]);
        } else {
          this.naja.uiHandler.submitForm(form);
        }
      } else {
        this.naja.makeRequest('POST', form.action, null);
      }
    }
  };
}

export default SubmitForm;
