import WavevisionUploader, {
  UploaderDeleteEvent,
  UploaderUploadEvent,
} from '@wavevision/uploader';
import getElementByClassName from '@wavevision/ts-utils/dom/getElementByClassName';
import getElementById from '@wavevision/ts-utils/dom/getElementById';
import getElementsByClassName from '@wavevision/ts-utils/dom/getElementsByClassName';
import getElementsBySelector from '@wavevision/ts-utils/dom/getElementsBySelector';
import { DATA_CONFIRM } from '@flowgate/core/ui/Components/Confirm/assets/scripts/constants';
import { Naja } from 'naja';

import NetteForms from 'nette-forms';

import makeMessages from './Messages';
import renderers from './Renderers';
import { CLASS_NAME, DATA, EMPTY, PARAMETER, UPLOADER } from './constants';
import { NetteRules, UploaderData } from './types';

const getItems = (): NodeListOf<HTMLElement> =>
  getElementsBySelector('[data-wavevision-uploader-item]');

const getSubmit = (): HTMLButtonElement | null => getElementById('filesSubmit');

const emptyItems = (): boolean => getItems().length === 0;

const toggleSubmit = (data: UploaderData, confirm: boolean): void => {
  if (data.validateSubmit) {
    const submit = getSubmit();
    if (submit) {
      if (confirm) {
        submit.setAttribute(DATA_CONFIRM, '');
      } else {
        submit.removeAttribute(DATA_CONFIRM);
      }
    }
  }
};

const handleDelete = (upload: HTMLElement, data: UploaderData, naja: Naja) => (
  e: UploaderDeleteEvent,
): void => {
  if (data.deleteUrl) {
    const url = data.deleteUrl.replace(PARAMETER, e.file.id);
    naja.makeRequest('DELETE', url, null);
  }
  setTimeout(() => {
    if (emptyItems()) {
      upload.classList.add(EMPTY);
      toggleSubmit(data, true);
    }
  });
};

const handleUpload = (upload: HTMLElement, data: UploaderData) => (
  e: UploaderUploadEvent,
): FileList | undefined => {
  const rules = JSON.parse(
    e.filesInput.getAttribute('data-nette-rules') || '[]',
  ) as NetteRules;
  for (const rule of rules.filter(r => r.op !== ':filled')) {
    if (!NetteForms.validateControl(e.filesInput, [rule], true)) {
      window.FlashMessage.showError({ title: rule.msg });
      return;
    }
  }
  if (e.filesInput.files) {
    upload.classList.remove(EMPTY);
    toggleSubmit(data, false);
    return e.filesInput.files;
  }
};

const initUpload = (upload: HTMLElement, naja: Naja): void => {
  const root = getElementByClassName(UPLOADER, upload) as HTMLElement;
  const dataAttribute = DATA.get(upload);
  if (!dataAttribute) {
    throw new Error(`Uploader is missing "${DATA.name()}" attribute.`);
  }
  const data: UploaderData = JSON.parse(dataAttribute);
  WavevisionUploader.init({
    link: {
      url: data.uploadUrl,
    },
    messages: makeMessages(data),
    onDelete: handleDelete(upload, data, naja),
    onUpload: handleUpload(upload, data),
    renderers,
    root,
  });
  if (upload.classList.contains(EMPTY)) toggleSubmit(data, true);
};

const init = (naja: Naja): void => {
  const uploads = getElementsByClassName(CLASS_NAME);
  for (const upload of uploads) {
    initUpload(upload, naja);
  }
};

export default { init };
