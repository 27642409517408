import dataAttribute from '@wavevision/ts-utils/dom/dataAttribute';

export const CLASS_NAME = 'upload';
export const EMPTY = `${CLASS_NAME}--empty`;
export const BUTTONS = `${CLASS_NAME}__buttons`;
export const CHOOSE = `${CLASS_NAME}__choose`;
export const DATA = dataAttribute('uploader');
export const DELETE_ALL = `${CLASS_NAME}__delete-all`;
export const UPLOADER = `${CLASS_NAME}__uploader`;
export const PARAMETER = '__FILE__';
export const SCREEN_DROPZONE = `${CLASS_NAME}__screen-dropzone`;
export const SCREEN_DROPZONE_INITIALIZED = dataAttribute(
  'screen-dropzone-initialized',
);
export const SCREEN_DROPZONE_HIDDEN = `${SCREEN_DROPZONE}--hidden`;
