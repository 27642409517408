import React, { useEffect, useRef, useState } from 'react';
import { render } from 'react-dom';
import InfoTooltip from '@flowgate/core/ui/Components/InfoTooltip/assets/scripts';
import Svg from '@flowgate/core/ui/Components/Svg/assets/scripts';

import arrowDouble from '@flowgate/online/assets/images/icons/arrow-double.svg';

import { HEADER_COLUMN_CLASS_NAME, TABLE_CLASS_NAME } from '../constants';

import { HeaderIconProps, RenderHeaderIcon, TableExpanded } from './types';

const HeaderIcon = (props: HeaderIconProps): JSX.Element => {
  const [expanded, setExpanded] = useState<TableExpanded>(false);
  const ref = useRef<HTMLSpanElement | null>(null);
  const onClick = (): void =>
    props.rows
      .filter(r => props.isExpanded(r) === expanded)
      .forEach(r => r.dispatchEvent(new Event('click')));
  useEffect(() => {
    props.setExpandedCallback(setExpanded);
  }, []);
  useEffect(() => {
    InfoTooltip.reinit(ref.current);
  });
  return (
    <span
      className={`${TABLE_CLASS_NAME}__expand-all has-tooltip`}
      onClick={onClick}
      ref={ref}
      role="button"
      title={expanded ? props.messages.true : props.messages.false}
    >
      <Svg symbol={arrowDouble} rotation={expanded ? 0 : -180} />
    </span>
  );
};

const renderHeaderIcon = (props: RenderHeaderIcon): void => {
  props.root.className = `${HEADER_COLUMN_CLASS_NAME} ${HEADER_COLUMN_CLASS_NAME}--icon`;
  render(
    <HeaderIcon
      {...props}
      messages={JSON.parse(
        props.root.getAttribute('data-expandable-messages') || '',
      )}
    />,
    props.root,
  );
};

export default renderHeaderIcon;
