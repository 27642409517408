import React, { ReactElement } from 'react';

const render = (message: string, image: string): ReactElement => (
  <div className="upload__drop-zone-message">
    <img alt={message} src={image} />
    <span>{message}</span>
  </div>
);

export default { render };
