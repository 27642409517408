import React, { useEffect, useState } from 'react';
import { render } from 'react-dom';
import Svg from '@flowgate/core/ui/Components/Svg/assets/scripts';
import arrowSimpleVertical from '@flowgate/core/assets/images/icons/arrow-simple-vertical.svg';

import { COLUMN_CLASS_NAME } from '../constants';

import { RowIconProps, TableExpanded } from './types';

export const CLASS_NAME = `${COLUMN_CLASS_NAME} ${COLUMN_CLASS_NAME}--icon`;

const RowIcon = (props: RowIconProps): JSX.Element => {
  const [expanded, setExpanded] = useState<TableExpanded>(false);
  const onClick = (e: Event): void => {
    if (!e.target) return;
    if ((e.target as HTMLElement).className !== 'dropdown-toggle') {
      props.onClick(props.row);
      setExpanded(expanded => !expanded);
    }
  };
  useEffect(() => {
    props.row.addEventListener('click', onClick);
    return () => props.row.removeEventListener('click', onClick);
  }, []);
  return <Svg symbol={arrowSimpleVertical} rotation={expanded ? 180 : 0} />;
};

const renderRowIcon = (props: RowIconProps): void => {
  const td = document.createElement('td');
  td.className = CLASS_NAME;
  props.row.appendChild(td);
  render(<RowIcon {...props} />, td);
};

export default renderRowIcon;
