export const BODY_SCROLLED = 'document__body--scrolled';
export const HEADER_BUILDER = 'header-container--builder';
export const HEADER_ID = '__header';
export const HEADER_SCROLLED = 'header-container--scrolled';
export const NAVIGATION_CLOSE = '__navigation-close';
export const NAVIGATION_HIDDEN = 'navigation-container--hidden';
export const NAVIGATION_ID = '__navigation';
export const NAVIGATION_MOBILE = '__navigation-mobile';
export const NAVIGATION_TOGGLE_HEIGHT = 120;
export const NAVIGATION_TOGGLER = '__navigation-toggler';
