import React, { ReactElement } from 'react';
import Svg from '@flowgate/core/ui/Components/Svg/assets/scripts';
import checkmark from '@flowgate/core/assets/images/icons/checkmark.svg';
import deleteIcon from '@flowgate/core/assets/images/icons/delete.svg';

import { formatSize } from './utils';
import './style.scss';

const renderProgress = (
  props: import('@wavevision/uploader').FileRendererProps,
): ReactElement => {
  if (props.handler.uploading) {
    const progress = Math.floor(Number(props.handler.progress));
    return (
      <div className="upload-progress">
        <div className="upload-progress__bar">
          <span style={{ width: `${progress}%` }} />
        </div>
        <div className="upload-progress__text">{progress} %</div>
      </div>
    );
  }
  return <Svg symbol={checkmark} />;
};

const File: import('@wavevision/uploader').FileRenderer = props => (
  <div className="upload-file">
    <div className="upload-file__name">{props.file.originalName}</div>
    <div className="upload-file__size">{formatSize(props.file.size)}</div>
    <div className="upload-file__progress">{renderProgress(props)}</div>
    <div
      className="upload-file__delete"
      onClick={props.handler.delete}
      role="button"
    >
      <Svg symbol={deleteIcon} />
    </div>
  </div>
);

export default File;
