import getElementByClassName from '@wavevision/ts-utils/dom/getElementByClassName';
import { Naja } from 'naja';
import { Extension } from 'naja/dist/Naja';

import { CLASS_NAME, METHOD, PAGES } from './constants';
import { getInput } from './utils';

class SpecificationForm implements Extension {
  public readonly initialize = (naja: Naja): void => {
    naja.addEventListener('init', this.load);
    naja.addEventListener('complete', this.load);
  };

  public readonly load = (): void => {
    const specification = getElementByClassName(CLASS_NAME);
    if (specification) {
      const method = getInput<HTMLSelectElement>(METHOD, specification);
      const pages = getInput<HTMLInputElement>(PAGES, specification);
      method.addEventListener('change', this.handleChange(pages));
    }
  };

  private readonly handleChange = (pages: HTMLInputElement) => (
    e: Event,
  ): void => {
    const target = e.target as HTMLSelectElement;
    const { value } = target;
    if (Number(pages.value) < Number(value)) {
      pages.value = value;
    }
  };
}

export default SpecificationForm;
