import { CarouselEvent } from 'bootstrap';

import {
  CAROUSEL_ACTIVE,
  CAROUSEL_CLASS_NAME,
  CAROUSEL_DATA,
  CAROUSEL_EVENT,
  INTERVAL_DATA,
} from './constants';

type $CarouselEvent = {
  relatedTarget: HTMLElement;
};

const findActive = (carousel: Element): Element =>
  carousel.getElementsByClassName(CAROUSEL_ACTIVE)[0];

const getInterval = (target: Element): number =>
  parseInt(target.getAttribute(INTERVAL_DATA) as string, 10);

const handleSlideTimeout = (carousel: JQuery<HTMLElement>) => (
  event: $CarouselEvent,
): void => {
  const data = carousel.data();
  data[CAROUSEL_DATA]._config.interval = getInterval(event.relatedTarget);
};

export default ($: JQueryStatic): void => {
  const carousels = document.getElementsByClassName(
    CAROUSEL_CLASS_NAME,
  ) as HTMLCollectionOf<HTMLElement>;
  for (const carousel of carousels) {
    const active = findActive(carousel);
    if (active) {
      const $carousel: JQuery<HTMLElement> = $(carousel);
      $carousel
        .carousel({
          interval: getInterval(active),
          pause: false,
          ride: 'carousel',
        })
        .on(CAROUSEL_EVENT as CarouselEvent, handleSlideTimeout($carousel));
    }
  }
};
