import { Naja } from 'naja';

import Expandable from './Expandable';
import Link from './Link';

export const getTable = (container: HTMLElement): HTMLTableElement => {
  const table = container.firstElementChild;
  if (table instanceof HTMLTableElement) {
    return table;
  }
  throw new Error(
    'Element with class "table" must contain single HTMLTableElement!',
  );
};

export const makeExpandable = (naja: Naja, table: HTMLElement): void => {
  if (Expandable.isExpandable(table)) {
    const expandable = new Expandable(naja, getTable(table));
    expandable.init();
  }
};

export const makeLink = (naja: Naja, table: HTMLElement): void => {
  const link = new Link(naja, getTable(table));
  link.init();
};
