import {
  FILTER_TOGGLE_ID,
  FILTER_TOGGLED,
  FILTERS,
  FILTER_ITEMS,
  FILTER_ITEMS_SHOW,
  FILTERS_TOGGLED,
} from './constants';

const toggleFilterValues = (values: HTMLElement): void => {
  if (values) {
    values.classList.toggle(FILTER_ITEMS_SHOW);
  }
};
const rotateToggleArrow = (arrowElement: HTMLElement): void => {
  if (arrowElement) {
    arrowElement.classList.toggle(FILTER_TOGGLED);
  }
};
const toggleValues = (): void => {
  for (const element of document.getElementsByClassName(FILTERS)) {
    const filter = document.getElementById(FILTER_TOGGLE_ID(element.id));
    const values = document.getElementById(FILTER_ITEMS(element.id));
    if (filter) {
      filter.addEventListener('click', () => {
        if (!values) return;
        toggleFilterValues(values);
        rotateToggleArrow(filter);
        element.classList.toggle(FILTERS_TOGGLED);
      });
    }
  }
};
export default (): void => {
  toggleValues();
};
