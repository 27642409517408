import $ from 'jquery';

import { DATA_ATTR, SELECTOR, INPUT_SELECTOR } from './constants';

function toggleHidden(this: HTMLElement, e: JQuery.Event): void {
  e.preventDefault();
  const selector = this.dataset[DATA_ATTR];
  if (selector) {
    const target = document.querySelector<HTMLElement>(selector);
    if (target) {
      target.hidden = !target.hidden;
      if (!target.hidden) {
        const input = target.querySelector<HTMLElement>(INPUT_SELECTOR);
        if (input) input.focus();
      }
    }
  }
}

($ => {
  $(document).on('click', SELECTOR, toggleHidden);
})($);
