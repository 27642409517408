import { UploaderData } from '../types';

import DropZone from './DropZone';

const makeMessages = (
  data: UploaderData,
): import('@wavevision/uploader').Messages => {
  const dropZone = DropZone.render(data.dropZoneMessage, data.dropZoneImage);
  return { dropMultipleFiles: dropZone, dropSingleFile: dropZone };
};

export default makeMessages;
