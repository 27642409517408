import $ from 'jquery';
import { AfterUpdateEvent } from 'naja/dist/core/SnippetHandler';
import { Extension } from 'naja/dist/Naja';
import { InteractionEvent } from 'naja/dist/core/UIHandler';
import { Naja } from 'naja';

import { BUTTON, BUTTON_LOADING, MODAL, SNIPPET } from './constants';

class ProductDetail implements Extension {
  private button: Element | null;

  private naja: Naja;

  public readonly initialize = (naja: Naja): void => {
    this.naja = naja;
    this.naja.addEventListener('init', this.load);
  };

  public readonly load = (): void => {
    const snippet: HTMLElement | null = document.getElementById(SNIPPET);
    if (!snippet) return;
    if (snippet.firstChild) {
      this.showDetail({ detail: { snippet } } as CustomEvent);
    }
    this.naja.uiHandler.addEventListener('interaction', this.showSpinner);
    this.naja.addEventListener('success', this.hideSpinner);
    this.naja.snippetHandler.addEventListener('afterUpdate', this.showDetail);
  };

  public readonly hideSpinner = (): void => {
    if (!this.button) return;
    this.button.classList.remove(BUTTON_LOADING);
    this.button = null;
  };

  public readonly showDetail = (event: AfterUpdateEvent): void => {
    const modal = event.detail.snippet.firstChild as Element | null;
    if (!modal || !modal.classList.contains(MODAL)) return;
    $(modal).modal('show');
  };

  public readonly showSpinner = ({
    detail: { element },
  }: InteractionEvent): void => {
    if (this.button) return;
    if (element.tagName.toLowerCase() === BUTTON) {
      this.button = element;
    } else {
      [this.button] = element.getElementsByClassName(
        BUTTON,
      ) as HTMLCollectionOf<HTMLElement>;
    }
    if (!this.button) return;
    this.button.classList.add(BUTTON_LOADING);
  };
}

export default ProductDetail;
