import { Naja } from 'naja';
import { Extension } from 'naja/dist/Naja';

import { DATA_LINK, PROCESSING_PAYMENT } from './constants';

class ProcessingPayment implements Extension {
  private link: string;

  private naja: Naja;

  public readonly initialize = (naja: Naja): void => {
    this.naja = naja;
    this.naja.addEventListener('init', this.load);
  };

  public load = (): void => {
    const processingPayment = document.getElementById(PROCESSING_PAYMENT);
    if (!processingPayment) return;
    const link = processingPayment.getAttribute(DATA_LINK);
    if (!link) throw new Error('Missing link for processing payment check!');
    this.link = link;
    this.naja.addEventListener('success', this.handleRequest);
    this.handleRequest();
  };

  public handleRequest = (): void => {
    this.naja.makeRequest('GET', this.link, null, {
      forceRedirect: true,
      history: false,
    });
  };
}

export default ProcessingPayment;
