import getElementById from '@wavevision/ts-utils/dom/getElementById';

const handleClick = (e: MouseEvent): void => {
  e.preventDefault();
  const files = getElementById('uploaded-files') as HTMLElement;
  if (files.getAttribute('style')) {
    files.removeAttribute('style');
  } else {
    files.style.display = 'block';
  }
};

const init = (): void => {
  const button = getElementById<HTMLButtonElement>('demand-add-files');
  if (button) {
    button.addEventListener('click', handleClick);
  }
};

export default { init };
