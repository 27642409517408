import { Extension } from 'naja/dist/Naja';

import DisallowMultipleSubmits from './DisallowMultipleSubmits';

const Forms: Extension = {
  initialize: naja => {
    DisallowMultipleSubmits.init(naja);
  },
};

export default Forms;
