import { Naja } from 'naja';
import { Extension } from 'naja/dist/Naja';
import getElementByClassName from '@wavevision/ts-utils/dom/getElementByClassName';

import ScreenDropzone from './scripts/ScreenDropzone';
import Uploader from './scripts';

import './styles/upload.scss';

const load = (naja: Naja) => (): void => {
  Uploader.init(naja);
  const dropzone = getElementByClassName('upload__screen-dropzone');
  if (dropzone) ScreenDropzone.init(dropzone);
};

const Upload: Extension = {
  initialize: naja => {
    naja.addEventListener('init', load(naja));
    naja.addEventListener('complete', load(naja));
    naja.snippetHandler.addEventListener('afterUpdate', load(naja));
  },
};

export default Upload;
