import $ from 'jquery';

import NetteForms from 'nette-forms';

const BASE = 'specification-part';
const TRIGGER = `.specification-control__header-wrap`;
const CLOSED = `${BASE}--closed`;
const FORM_CLASS = 'online-print-form';

const init = (): void => {
  const forms = document.getElementsByClassName(FORM_CLASS);
  if (forms.length === 0) return;
  $(document).on('click', TRIGGER, (e: JQuery.MouseEventBase) => {
    //todo remember closed after refresh?
    const $part = $(e.target).closest(`.${BASE}`);
    if ($part.hasClass(CLOSED)) {
      $part.removeClass(CLOSED);
    } else {
      $part.addClass(CLOSED);
    }
  });
  for (const form of forms) {
    form.addEventListener('submit', e => {
      if (!NetteForms.validateForm(e.target as HTMLFormElement, true)) {
        $(`.${CLOSED}`).removeClass(CLOSED);
      }
    });
  }
};

export default { init };
