import { Naja } from 'naja';
import { Extension } from 'naja/dist/Naja';

import { DATA_ATTR } from './constants';
import { ParsedAttribute } from './types';

class DisableOnInputValue implements Extension {
  public readonly initialize = (naja: Naja): void => {
    naja.addEventListener('init', this.load);
    naja.addEventListener('complete', this.load);
  };

  public readonly load = (): void => {
    const inputs = document.querySelectorAll(
      `[${DATA_ATTR}]`,
    ) as NodeListOf<HTMLInputElement>;
    for (const input of inputs) {
      this.bindListener(this.parseAttribute(input), input);
    }
  };

  public getParentValue = (parent: HTMLInputElement): unknown => {
    if (parent.type === 'checkbox') return parent.checked;
    return parent.value;
  };

  public bindListener = (
    attribute: ParsedAttribute | null,
    input: HTMLInputElement,
  ): void => {
    if (!attribute) return;
    const parent = document.getElementById(attribute.id);
    if (!parent) {
      throw new Error(`Parent input ID ${attribute.id} does not exist!`);
    }
    parent.addEventListener(
      'change',
      this.handleChange(input, attribute.value),
    );
  };

  public handleChange = (input: HTMLInputElement, parentValue: unknown) => (
    e: Event,
  ): void => {
    const value = this.getParentValue(e.target as HTMLInputElement);
    if (String(value) === String(parentValue)) {
      input.disabled = true;
      input.value = '';
      input.dispatchEvent(new Event('change'));
    } else {
      input.disabled = false;
    }
  };

  public parseAttribute = (input: HTMLElement): ParsedAttribute | null => {
    const attr = input.attributes.getNamedItem(DATA_ATTR);
    if (!attr) return null;
    return JSON.parse(attr.value);
  };
}

export default DisableOnInputValue;
