import LiveFormValidation, { LiveForm, Nette } from 'live-form-validation-es6';

const CHOICE_CONTROL_HAS_ERROR = 'data-has-error';

LiveForm.setOptions({
  controlErrorClass: 'form-group--error',
  controlValidClass: 'form-group--valid',
  messageParentClass: 'form-check',
  showValid: true,
});

const handleChoiceControls = (el: HTMLElement): void => {
  const rules = el.getAttribute('data-nette-rules');
  if (!rules || el.getAttribute(CHOICE_CONTROL_HAS_ERROR) != null) return;
  const rule = JSON.parse(rules).shift();
  window.FlashMessage.showError({
    title: rule.msg,
    onDelete: () => el.removeAttribute(CHOICE_CONTROL_HAS_ERROR),
  });
  el.setAttribute(CHOICE_CONTROL_HAS_ERROR, 'true');
};

const handleAddError = (el: HTMLFormElement, message: string): void => {
  if (el.type === 'checkbox' || el.type === 'radio') {
    return handleChoiceControls(el);
  }
  const groupEl = LiveForm.getGroupElement(el);
  LiveForm.setFormProperty(el.form, 'hasError', true);
  LiveForm.removeClass(groupEl, LiveForm.options.controlValidClass);
  LiveForm.addClass(groupEl, LiveForm.options.controlErrorClass);
  const errIcon = el.parentNode?.querySelector(
    '.form-control-validation__error',
  );
  if (errIcon) errIcon.setAttribute('data-original-title', message);
};

LiveForm.addError = handleAddError;
Nette.initOnLoad();

export default LiveFormValidation;
