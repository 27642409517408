import getElementBySelector from '@wavevision/ts-utils/dom/getElementBySelector';

import {
  SCREEN_DROPZONE_HIDDEN,
  SCREEN_DROPZONE_INITIALIZED,
} from './constants';

const init = (dropzone: HTMLElement): void => {
  const input = getElementBySelector<HTMLInputElement>(
    '[data-point-uploader]',
    dropzone.parentElement || undefined,
  );
  if (!SCREEN_DROPZONE_INITIALIZED.has(dropzone) && input) {
    const hide = (): void => dropzone.classList.add(SCREEN_DROPZONE_HIDDEN);
    const show = (): void => dropzone.classList.remove(SCREEN_DROPZONE_HIDDEN);
    const allowDrag = (e: DragEvent): void => {
      if (e.dataTransfer) {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
      }
    };
    const handleDrop = (e: DragEvent): void => {
      if (e.dataTransfer) {
        e.preventDefault();
        const { files } = e.dataTransfer;
        input.files = files;
        input.dispatchEvent(new Event('change', { bubbles: true }));
        hide();
      }
    };
    window.addEventListener('dragenter', show);
    dropzone.addEventListener('dragenter', allowDrag);
    dropzone.addEventListener('dragover', allowDrag);
    dropzone.addEventListener('dragleave', hide);
    dropzone.addEventListener('drop', handleDrop);
    SCREEN_DROPZONE_INITIALIZED.assign(dropzone);
  }
};

export default { init };
