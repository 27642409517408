import { Naja } from 'naja';

import { LINK_DATA } from './constants';

class Link {
  public constructor(naja: Naja, table: HTMLTableElement) {
    this.naja = naja;
    this.table = table;
    this.naja.addEventListener('success', this.init);
  }

  public naja: Naja;

  public table: HTMLTableElement;

  public init = (): void => {
    for (const row of this.table.rows) {
      const link = row.getAttribute(LINK_DATA);
      if (link) row.addEventListener('click', this.handleClick(link));
    }
  };

  public handleClick = (link: string) => (): void => {
    window.location.href = link;
  };
}

export default Link;
