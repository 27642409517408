import { EVENT_LOADED, EVENT_LOADING } from '@wavevision/dependent-selectbox';

import {
  DependentHandlerInit,
  DependentSelectBoxCallback,
  DependentSelectBoxCallbackData,
  DependentSelectBoxEventHandler,
} from './types';

const handleCallback = (
  data: DependentSelectBoxCallbackData,
  callback?: DependentSelectBoxCallback,
): void => {
  if (typeof callback === 'function') {
    return callback(data);
  }
};

class DependentHandler {
  private forms: Record<string, boolean> = {};

  public readonly init: DependentHandlerInit = (naja, callbacks = {}) => {
    naja.addEventListener(
      EVENT_LOADED,
      this.dependentSelectBoxLoaded(callbacks.onLoaded),
    );
    naja.addEventListener(
      EVENT_LOADING,
      this.dependentSelectBoxLoading(callbacks.onLoading),
    );
  };

  public readonly addForm = (form: HTMLFormElement): DependentHandler => {
    if (this.forms[form.id] === undefined) {
      this.forms[form.id] = true;
    }
    return this;
  };

  public readonly isReady = (form: HTMLFormElement): boolean =>
    this.forms[form.id];

  private readonly dependentSelectBoxLoaded: DependentSelectBoxEventHandler = callback => ({
    detail: { form, dependentSelectBoxes },
  }) => {
    if (form.id in this.forms) this.forms[form.id] = true;
    return handleCallback({ form, dependentSelectBoxes }, callback);
  };

  private readonly dependentSelectBoxLoading: DependentSelectBoxEventHandler = callback => ({
    detail: { form, dependentSelectBoxes },
  }) => {
    if (form.id in this.forms) this.forms[form.id] = false;
    return handleCallback({ form, dependentSelectBoxes }, callback);
  };
}

export default DependentHandler;
