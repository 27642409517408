import { Naja } from 'naja';
import { Extension } from 'naja/dist/Naja';

import { makeExpandable, makeLink } from './utils';
import { TABLE_CLASS_NAME } from './constants';

const load = (naja: Naja) => (): void => {
  for (const table of document.getElementsByClassName(
    TABLE_CLASS_NAME,
  ) as HTMLCollectionOf<HTMLElement>) {
    makeExpandable(naja, table);
    makeLink(naja, table);
  }
};

const Table: Extension = {
  initialize: naja => {
    naja.addEventListener('init', load(naja));
    naja.addEventListener('complete', load(naja));
  },
};

export default Table;
