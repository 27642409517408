import { Naja } from 'naja';

import renderHeaderIcon from './renderHeaderIcon';
import renderRowIcon, { CLASS_NAME } from './renderRowIcon';
import { EXPANDABLE_ROW, EXPANDABLE_TABLE, EXPANDED_ROW } from './constants';
import { Table, TableExpandedCallback, TableRow, TableRows } from './types';

const isEmpty = <T>(collection: Array<T>): boolean => collection.length === 0;

class Expandable {
  public static isExpandable = (table: HTMLElement): boolean =>
    table.classList.contains(EXPANDABLE_TABLE);

  public constructor(naja: Naja, table: Table) {
    this.naja = naja;
    this.table = table;
    this.naja.addEventListener('success', this.initRows);
  }

  public naja: Naja;

  public table: Table;

  public expandedCallback: TableExpandedCallback;

  public init = (): void => {
    this.initHeader();
    this.initRows();
  };

  public initHeader = (): void => {
    if (!this.table.tHead) return;
    const header = this.table.tHead.rows.item(this.table.tHead.rows.length - 1);
    if (!header || !header.lastElementChild) return;
    renderHeaderIcon({
      isExpanded: this.isExpanded,
      root: header.lastElementChild,
      rows: this.getRows(),
      setExpandedCallback: callback => {
        this.expandedCallback = callback;
      },
    });
  };

  public initRow = (row: TableRow): void => {
    if (
      !row.lastElementChild ||
      row.lastElementChild.className !== CLASS_NAME
    ) {
      renderRowIcon({
        onClick: this.handleClick,
        row,
      });
    }
  };

  public initRows = (): void => this.getRows().forEach(this.initRow);

  public getRows = (): TableRows =>
    Array.from(this.table.rows).filter(this.isExpandable);

  public isAnyExpanded = (): boolean =>
    !isEmpty(this.getRows().filter(this.isExpanded));

  public isExpandable = (row: TableRow): boolean =>
    row.classList.contains(EXPANDABLE_ROW);

  public isExpanded = (row: TableRow): boolean =>
    row.classList.contains(EXPANDED_ROW);

  public handleClick = (row: TableRow): void => {
    if (this.isExpanded(row)) {
      this.handleCollapse(row);
    } else {
      this.handleExpand(row);
    }
    this.expandedCallback(this.isAnyExpanded());
  };

  public handleExpand = (row: TableRow): void =>
    row.classList.add(EXPANDED_ROW);

  public handleCollapse = (row: TableRow): void =>
    row.classList.remove(EXPANDED_ROW);
}

export default Expandable;
