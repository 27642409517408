import { CONTROLS, CONTROLS_CLOSE, CONTROLS_OPEN } from './constants';

const openElement = document.getElementById(CONTROLS_OPEN);
const closeElement = document.getElementById(CONTROLS_CLOSE);
const controls = document.getElementById(CONTROLS);

const showControls = (): void => {
  if (!controls) return;
  controls.classList.remove('d-none');
  controls.classList.add('d-flex');
};
const hideControls = (): void => {
  if (!controls) return;
  controls.classList.add('d-none');
  controls.classList.remove('d-flex');
};
const handleShowControls = (): void => {
  if (!openElement) return;
  openElement.addEventListener('click', showControls);
};
const handleCloseControls = (): void => {
  if (!closeElement) return;
  closeElement.addEventListener('click', hideControls);
};

export default (): void => {
  handleShowControls();
  handleCloseControls();
};
