import { initDropdowns } from '@flowgate/core/ui/Components/Dropdown/assets/scripts/utils';
import { Extension } from 'naja/dist/Naja';

const Dropdown: Extension = {
  initialize: naja => {
    naja.addEventListener('init', initDropdowns);
    naja.addEventListener('complete', initDropdowns);
  },
};

export default Dropdown;
