import { Naja } from 'naja';
import { Extension } from 'naja/dist/Naja';

import { SNIPPET } from './constants';

class ProductDetail implements Extension {
  private className: string;

  private snippet: HTMLElement;

  private naja: Naja;

  public readonly initialize = (naja: Naja): void => {
    this.naja = naja;
    this.naja.addEventListener('init', this.load);
  };

  public readonly load = (): void => {
    const snippet = document.getElementById(SNIPPET);
    if (!snippet) return;
    this.snippet = snippet;
    if (!this.snippet.parentElement) return;
    this.className = this.snippet.parentElement.className;
    this.naja.addEventListener('before', this.handleInteraction);
    this.naja.snippetHandler.addEventListener('afterUpdate', () => {
      setTimeout(this.handleComplete, 1000);
    });
  };

  public readonly handleComplete = (): void => {
    if (!this.snippet.parentElement) return;
    this.snippet.parentElement.className = this.className;
    this.snippet.removeAttribute('style');
  };

  public readonly handleInteraction = (): void => {
    if (!this.snippet.parentElement) return;
    this.snippet.style.height = `${this.snippet.clientHeight}px`;
    this.snippet.parentElement.className = `${this.className} ${this.className}--loading`;
  };
}

export default ProductDetail;
