import getElementBySelector from '@wavevision/ts-utils/dom/getElementBySelector';

export const getInput = <T extends HTMLElement>(
  name: string,
  specification: HTMLElement,
): T =>
  getElementBySelector(
    `[data-specification-input="${name}"]`,
    specification,
  ) as T;
