import getElementsByClassName from '@wavevision/ts-utils/dom/getElementsByClassName';
import { InteractionEvent } from 'naja/dist/core/UIHandler';
import { Naja } from 'naja';

import { DISALLOW_MULTIPLE_SUBMITS } from './constants';

let FORMS: HTMLFormElement[] = [];

const preventSubmit = (e: Event): void => e.preventDefault();

const toggle = (
  form: HTMLFormElement,
  callback: (button: HTMLElement) => void,
): void => {
  for (const input of form.elements as HTMLCollectionOf<HTMLInputElement>) {
    if (['button', 'submit'].includes(input.type)) callback(input);
  }
};

const enableSubmits = (form: HTMLFormElement): void => {
  toggle(form, button => {
    button.removeEventListener('click', preventSubmit);
    button.classList.remove('disabled');
  });
  FORMS = FORMS.filter(f => f !== form);
};

const disableSubmits = (form: HTMLFormElement): void => {
  if (!FORMS.includes(form)) FORMS = [...FORMS, form];
  toggle(form, button => {
    button.addEventListener('click', preventSubmit);
    button.classList.add('disabled');
  });
};

const onInputInteraction = (input: HTMLInputElement): void => {
  if (input.form) disableSubmits(input.form);
};

const onInteraction = (e: InteractionEvent): void => {
  const { element } = e.detail;
  switch (element.constructor) {
    case HTMLInputElement:
    case HTMLButtonElement:
      return onInputInteraction(element as HTMLInputElement);
    case HTMLFormElement:
      return disableSubmits(element as HTMLFormElement);
  }
};

const onSubmit = (e: Event): void => {
  const form = e.target as HTMLFormElement;
  if (FORMS.includes(form)) preventSubmit(e);
  disableSubmits(form);
};

const reset = (): void => FORMS.forEach(enableSubmits);

const bindForm = (form: HTMLFormElement): void => {
  if (DISALLOW_MULTIPLE_SUBMITS.has(form)) return;
  form.addEventListener('submit', onSubmit);
  form.addEventListener('change', reset);
  DISALLOW_MULTIPLE_SUBMITS.assign(form);
};

const bind = (): void => {
  for (const form of getElementsByClassName('form')) {
    if (form instanceof HTMLFormElement) bindForm(form);
  }
};

const init = (naja: Naja): void => {
  naja.addEventListener('init', bind);
  naja.addEventListener('complete', reset);
  naja.uiHandler.addEventListener('interaction', onInteraction);
  naja.snippetHandler.addEventListener('afterUpdate', bind);
};

export default { init };
