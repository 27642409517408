import {
  BODY_SCROLLED,
  HEADER_BUILDER,
  HEADER_ID,
  HEADER_SCROLLED,
  NAVIGATION_HIDDEN,
  NAVIGATION_ID,
  NAVIGATION_TOGGLE_HEIGHT,
} from './constants';

const navigation = document.getElementById(NAVIGATION_ID);
const header = document.getElementById(HEADER_ID);
const toggleNavigation = (): void => {
  if (header && navigation) {
    if (!header.classList.contains(HEADER_BUILDER)) {
      const currentScrollPosition = window.pageYOffset;
      if (currentScrollPosition > NAVIGATION_TOGGLE_HEIGHT) {
        document.body.classList.add(BODY_SCROLLED);
        header.classList.add(HEADER_SCROLLED);
        navigation.classList.add(NAVIGATION_HIDDEN);
      } else {
        document.body.classList.remove(BODY_SCROLLED);
        header.classList.remove(HEADER_SCROLLED);
        navigation.classList.remove(NAVIGATION_HIDDEN);
      }
    }
  }
};
export default (): void => {
  window.addEventListener('scroll', toggleNavigation);
};
