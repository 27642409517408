import {
  NAVIGATION_CLOSE,
  NAVIGATION_HIDDEN,
  NAVIGATION_MOBILE,
  NAVIGATION_TOGGLER,
} from './constants';

const navigationToggler = document.getElementById(NAVIGATION_TOGGLER);
const mobileNavigation = document.getElementById(NAVIGATION_MOBILE);
const close = document.getElementById(NAVIGATION_CLOSE);

const toggleMobileNavigation = (): void => {
  if (mobileNavigation) {
    if (mobileNavigation.classList.contains(NAVIGATION_HIDDEN)) {
      mobileNavigation.classList.remove(NAVIGATION_HIDDEN);
    } else {
      mobileNavigation.classList.add(NAVIGATION_HIDDEN);
    }
  }
};

const closeMobileNavigation = (): void => {
  if (mobileNavigation) {
    mobileNavigation.classList.add(NAVIGATION_HIDDEN);
  }
};

export default (): void => {
  if (navigationToggler) {
    navigationToggler.addEventListener('click', toggleMobileNavigation);
  }
  if (close) {
    close.addEventListener('click', closeMobileNavigation);
  }
};
